<template>
  <div>
    <v-tabs
      align-with-title
    >
      <v-tabs-slider color="accent"></v-tabs-slider>
        <v-tab
          :to="{ name: 'Mortgage Tax - Create Fiscal Year' }"
        >
        Create New Fiscal Year
        </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'MortgageTaxUtils',
  components: {
  },
  created() {
  },
  computed: {
  },
  methods: {
  },
};
</script>
